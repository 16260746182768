 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="110px">
			<el-form-item class="el_form_item" label="联系人电话">
				<el-input class="el_input" v-model="form.user_tel" placeholder="联系人电话搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="联系人姓名">
				<el-input class="el_input" v-model="form.user_name" placeholder="联系人姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="客户公司名称">
				<el-input class="el_input" v-model="form.company_name" placeholder="客户公司名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="发货地区">
				<el-cascader class="el_input" v-model="form.case_city" placeholder="请选择车型" :options="cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="卸货地区">
				<el-cascader class="el_input" v-model="form.aim_city" placeholder="请选择车型" :options="cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算方式">
				<el-select class="el_input" v-model="form.settlement_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="按吨数" value="1"></el-option>
					<el-option label="按方数" value="2"></el-option>
					<el-option label="按趟" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="必填货物重量">
				<el-select class="el_input" v-model="form.is_need_weight" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="必填货物体积">
				<el-select class="el_input" v-model="form.is_need_volume" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否双边">
				<el-select class="el_input" v-model="form.is_need_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="ser">查询</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="添加时间" width="170">
					<template slot-scope="scope">
						<div>{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="客户信息" width="200">
					<template slot-scope="scope">
						<div class="tab_line_item">(姓名){{scope.row.shipper_name}}</div>
						<div class="tab_line_item">(电话){{scope.row.shipper_tel}}</div>
						<div class="tab_line_item">(公司){{scope.row.shipper_company_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省/市/区) {{scope.row.case_prov}} / {{scope.row.case_city}} / {{scope.row.case_county}}</div>
						<div class="tab_line_item">(详细地址) {{scope.row.case_addr?scope.row.case_addr:'未设置'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省/市/区) {{scope.row.aim_prov}} / {{scope.row.aim_city}} / {{scope.row.aim_county}}</div>
						<div class="tab_line_item">(详细地址) {{scope.row.aim_addr?scope.row.aim_addr:'未设置'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货物名称/运输距离"  width="200">
					<template slot-scope="scope">
						<div class="tab_line_item">(货物名称){{scope.row.cargo_name}}</div>
						<div class="tab_line_item">(运输距离){{scope.row.distance}} 公里</div>
					</template>
				</el-table-column>
				<el-table-column label="必填项/回程"  width="130">
					<template slot-scope="scope">
						<div class="tab_line_item">(结算方式) {{scope.row.settlement_type_text}}</div>
						<div class="tab_line_item">(必填重量) {{scope.row.is_need_weight_text}}</div>
						<div class="tab_line_item">(必填体积) {{scope.row.is_need_volume_text}}</div>
						<div class="tab_line_item">(是否双边) {{scope.row.is_need_return_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="200">
					<template slot-scope="scope">
						<el-button @click="open_edit_view(scope.row)" size="mini" type="text">修改</el-button>
						<el-button @click="del(scope.row)" size="mini" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 添加弹出层 -->
		<blAdd :shipper_list="shipper_list.list" :is_show="add.is_show" @sub="add_sub"></blAdd>

		<!-- 修改弹出层 -->
		<blEdit :shipper_list="shipper_list.list" :is_show="edit.is_show" :old_data="edit.old_data" @sub="edit_sub"></blEdit>

	</div>
</template>

<script>
	import blAdd from './add.vue';
	import blEdit from './edit.vue';
	import {regionDataPlus,CodeToText} from 'element-china-area-data'
	export default {
		components:{
			blAdd,
			blEdit
		},
		data() {
			return {

				//省市县基础数据
				cities:regionDataPlus,

				//搜索条件
				form: {
					
					//客户联系电话
					user_tel:'',

					//客户联系人
					user_name:'',

					//客户公司名称
					company_name:'',

					//货物名称
					cargo_name:'',

					//发货省市区
					case_city:null,

					//卸货省市区
					aim_city:null,

					//是否必填货物重量(1:是,2:否)
					is_need_weight:'',

					//是否必填货物体积(1:是,2:否)
					is_need_volume:'',

					//是否回程(1:需要,2:不需要)
					is_need_return:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//客户列表
				shipper_list:{

					//是否读取过
					is_readed:false,

					//结果集
					list:[],
				},
				
				//添加界面
				add:{

					//是否显示
					is_show:0
				},

				edit:{

					//是否显示
					is_show:0,

					//旧数据
					old_data:{}
				},
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()

			//读取客户数据
			this.$my.net.req({
				data:{
					mod:'company',
					ctr:'company_list_admin',
					p:1,
					num:100000,
				},
				callback:(data)=>{

					//置入结果集
					this.shipper_list.list=data.list

					//已读取过
					this.shipper_list.is_readed=true;
				}
			})
		},
		methods: {

			//删除
			del(item){

				//询问
				this.$my.other.confirm({
					content:"点击'确定'删除路线",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_business_line',
								ctr:'shipper_bl_del_admin',
								id:item.id
							},
							callback:(data)=>{
								
								//读取页面数据
								this.get_page_data()
							}
						})
					}
				});
			},
			
			//打开客户添加界面
			open_add_view(){

				//如果已读取
				if(this.shipper_list.is_readed){

					//如果数据为空
					if(this.shipper_list.list.length==0){
						this.$my.other.msg({
							type:'warning',
							str:"客户数据为空,请先添加客户数据"
						});
						return;
					}else{

						//打开界面
						this.add.is_show++;
					}
				}else{

					this.$my.other.msg({
						type:'warning',
						str:"客户数据未读取完成, 请稍后或者刷新后再试"
					});
					return;
				}
			},
			add_sub(form){//添加
				
				//报告结果
				this.$my.other.msg({
					type:'success',
					str:"添加成功"
				});

				//关闭页面
				this.add.is_show=0
				
				//刷新页面
				this.get_page_data()
			},

			//打开客户修改界面
			open_edit_view(item){

				//置入数据
				this.edit.old_data=item

				//打开界面
				this.edit.is_show++;
			},
			edit_sub(form){
				
				//报告结果
				this.$my.other.msg({
					type:'success',
					str:"修改成功"
				});

				//关闭页面
				this.edit.is_show=0

				//读取页面数据
				this.get_page_data()
			},

			//搜索
			ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_business_line',
						ctr:'shipper_bl_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//结算方式(1:按吨数,2:按方数,3:按趟)
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}

							//是否必填货物重量(1:是,2:否)
							switch(item.is_need_weight){
								case '1':item.is_need_weight_text="是";break;
								case '2':item.is_need_weight_text="否";break;
							}

							//是否必填货物体积(1:是,2:否)
							switch(item.is_need_volume){
								case '1':item.is_need_volume_text="是";break;
								case '2':item.is_need_volume_text="否";break;
							}

							//是否回程(1:需要,2:不需要)
							switch(item.is_need_return){
								case '1':item.is_need_return_text="是";break;
								case '2':item.is_need_return_text="否";break;
							}
						}
						
						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>