 <template>
	<el-dialog title="添加业务线" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form label-position="left" label-width="75px">
			<el-form-item label="装货地">
				<el-cascader 
					v-model="form.case_city" 
					placeholder="装货地省市区" 
					:options="cities"
					style="width: 100%;" filterable
				></el-cascader>
				<el-input 
					v-model="form.case_addr" 
					placeholder="装货地详细地址"
					style="margin-top: 2px;" clearable
				></el-input>
			</el-form-item>
			<el-form-item label="到货地">
				<el-cascader 
					v-model="form.aim_city" 
					placeholder="装货地省市区" 
					:options="cities"
					style="width: 100%;" filterable
				></el-cascader>
				<el-input 
					v-model="form.aim_addr" 
					placeholder="装货地详细地址" 
					style="margin-top: 2px;" clearable
				></el-input>
			</el-form-item>
			<el-form-item label="运输距离">
				<el-input v-model="form.distance" type="number" placeholder="请输入运输距离" clearable>
					<el-button slot="append">公里</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="货物名称 必填数据">
				<el-input v-model="form.cargo_name" placeholder="请输入货物名称" clearable></el-input>
				<div style="margin-top: 2px;display: flex;justify-content: space-between;">
					<el-col :span="6">
						<el-select v-model="form.settlement_type" @change="settlement_type_change" placeholder="结算方式">
							<el-option label="按吨数" value="1"></el-option>
							<el-option label="按方数" value="2"></el-option>
							<el-option label="按趟" value="3"></el-option>
						</el-select>
					</el-col>
					<el-col :span="6">
						<el-select v-model="form.is_need_weight" :disabled="form.is_need_weight_lock" placeholder="是否必填吨数">
							<el-option label="必填吨数" value="1"></el-option>
							<el-option label="不必填吨数" value="2"></el-option>
						</el-select>
					</el-col>
					<el-col :span="6">
						<el-select v-model="form.is_need_volume" :disabled="form.is_need_volume_lock" placeholder="是否必填体积">
							<el-option label="必填体积" value="1"></el-option>
							<el-option label="不必填体积" value="2"></el-option>
						</el-select>
					</el-col>
					<el-col :span="6">
						<el-select v-model="form.is_need_return" placeholder="是否回程">
							<el-option label="是双程" value="1"></el-option>
							<el-option label="不是双程" value="2"></el-option>
						</el-select>
					</el-col>
				</div>
			</el-form-item>
			<el-form-item label="线路客户 线路备注">
				<el-select v-model="form.shipper_num" style="width: 100%;" placeholder="请选择线路归属客户" clearable>
					<el-option
						v-for="(item,index) in shipper_list"
						:key="index"
						:label="item.name+' / '+item.user_name+' / '+item.user_tel"
						:value="item.user_num"
					></el-option>
				</el-select>
				<el-input v-model="form.mark" style="margin-top: 2px;" placeholder="请输入线路备注" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import {regionDataPlus,CodeToText,TextToCode} from 'element-china-area-data'
	export default {
		props:{
			shipper_list:Array,
			old_data:Object,
			is_show:Number,
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//省市县基础数据
				cities:regionDataPlus,

				//搜索条件
				form: {

					//货主编号
					shipper_num:'',

					//发货省市区
					case_city:null,

					//发货省详细地址
					case_addr:"",

					//卸货省市区
					aim_city:null,
					
					//卸货详细地址
					aim_addr:"",

					//运输距离
					distance:'',

					//货物名称
					cargo_name:'',

					//结算方式(1:按吨数,2:按方数,3:按趟)
					settlement_type:'',

					//是否必填货物重量(1:是,2:否)
					is_need_weight:'',
					is_need_weight_lock:false,

					//是否必填货物体积(1:是,2:否)
					is_need_volume:'',
					is_need_volume_lock:false,

					//是否回程(1:需要,2:不需要)
					is_need_return:'',

					//备注
					mark:''
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.init();
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//结算方式变化
			settlement_type_change(){

				switch(this.form.settlement_type){
					
					case '1'://按吨数结算
						this.form.is_need_weight='1';//吨数必填
						this.form.is_need_weight_lock=true;//锁定吨数必填选择器
						this.form.is_need_volume_lock=false;//解锁方数必填选择器
						break;
					
					case '2'://按体积结算
						this.form.is_need_volume='1';//体积必填
						this.form.is_need_weight_lock=false;//解锁吨数必填选择器
						this.form.is_need_volume_lock=true;//锁定方数必填选择器
						break;
					
					case '3'://按趟结算
						this.form.is_need_weight_lock=false;//解锁吨数必填选择器
						this.form.is_need_volume_lock=false;//解锁方数必填选择器
						break;
				}
			},

			//初始化
			init(){

				//线路客户
				this.form.shipper_num=this.old_data.shipper_num

				//发货省市区
				this.form.case_city=[
					TextToCode[this.old_data.case_prov].code,
					TextToCode[this.old_data.case_prov][this.old_data.case_city].code,
					TextToCode[this.old_data.case_prov][this.old_data.case_city][this.old_data.case_county].code
				]

				//发货省详细地址
				this.form.case_addr=this.old_data.case_addr

				//到货省市区
				this.form.aim_city=[
					TextToCode[this.old_data.aim_prov].code,
					TextToCode[this.old_data.aim_prov][this.old_data.aim_city].code,
					TextToCode[this.old_data.aim_prov][this.old_data.aim_city][this.old_data.aim_county].code
				]

				//到货省详细地址
				this.form.aim_addr=this.old_data.aim_addr

				//运输距离
				this.form.distance=this.old_data.distance

				//货物名称
				this.form.cargo_name=this.old_data.cargo_name

				//结算方式
				this.form.settlement_type=this.old_data.settlement_type

				//三个是否必填
				this.form.is_need_weight=this.old_data.is_need_weight
				this.form.is_need_volume=this.old_data.is_need_volume
				this.form.is_need_return=this.old_data.is_need_return

				//备注
				this.form.mark=this.old_data.mark

				//打开弹出层
				this.is_show_in_page=true;

				//结算方式变化
				this.settlement_type_change();
			},
			
			//提交
			sub(){

				//装货地省市县
				if(!this.$my.check.is_arr(this.form.case_city)){
					this.$my.other.msg({
						type:'warning',
						str:"装货地城市数据为空,请选择装货地省市县"
					});
					return;
				}else{
					this.form.case_prov_name=CodeToText[this.form.case_city[0]];
					this.form.case_city_name=CodeToText[this.form.case_city[1]];
					this.form.case_county_name=CodeToText[this.form.case_city[2]];
				}

				//装货地详情
				if(!this.form.case_addr.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"装货地详情数据为空,请输入装货地地址"
					});
					return;
				}

				//到货地省市县
				if(!this.$my.check.is_arr(this.form.aim_city)){
					this.$my.other.msg({
						type:'warning',
						str:"到货地城市数据为空,请选择到货地省市县"
					});
					return;
				}else{
					this.form.aim_prov_name=CodeToText[this.form.aim_city[0]];
					this.form.aim_city_name=CodeToText[this.form.aim_city[1]];
					this.form.aim_county_name=CodeToText[this.form.aim_city[2]];
				}

				//到货地详情
				if(!this.form.aim_addr.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"到货地详情数据为空,请输入到货地地址"
					});
					return;
				}
				
				//运输距离
				if(!(this.$my.check.is_num(this.form.distance)&&this.form.distance>0)){
					this.$my.other.msg({
						type:'warning',
						str:"运输距离不能小等于0,请输入"
					});
					return;
				}

				//货物名称
				if(!this.form.cargo_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"货物名称为空,请输入货物名称"
					});
					return;
				}

				//货主编号
				if(!this.form.shipper_num.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"货主编号为空,请选择货主编号"
					});
					return;
				}

				//结算方式
				if(this.form.settlement_type=='1'&&this.form.is_need_weight!='1'){//按吨数结算时吨数没有必填
					this.$my.other.msg({
						type:'warning',
						str:"按吨数结算时吨数没有必填"
					});
					return;
				}
				if(this.form.settlement_type=='2'&&this.form.is_need_volume!='1'){//按体积结算时体积没有必填
					this.$my.other.msg({
						type:'warning',
						str:"按体积结算时体积没有必填"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_business_line',
						ctr:'shipper_bl_edit_admin',
						id:this.old_data.id,
						shipper_num:this.form.shipper_num.trim(),
						case_prov:this.form.case_prov_name,
						case_city:this.form.case_city_name,
						case_county:this.form.case_county_name,
						case_addr:this.form.case_addr.trim(),
						aim_prov:this.form.aim_prov_name,
						aim_city:this.form.aim_city_name,
						aim_county:this.form.aim_county_name,
						aim_addr:this.form.aim_addr.trim(),
						distance:this.form.distance,
						cargo_name:this.form.cargo_name,
						settlement_type:this.form.settlement_type,
						is_need_weight:this.form.is_need_weight,
						is_need_volume:this.form.is_need_volume,
						is_need_return:this.form.is_need_return,
						mark:this.form.mark,
					},
					callback:(data)=>{

						//关闭弹出层
						// this.is_show_in_page=false;

						//通知
						this.$emit("sub");
					}
				});
			},
		}
	}
</script>